<template>
    <v-main>
        <v-container>
            <div class="text-h4 mb-6">Scan for Inventory Issues</div>
            <v-row v-if="!issues.length" style="height: 300px" align="center" class="rounded-lg grey lighten-4">
                <v-col>
                    <v-row justify="center">
                        <v-alert
                            dense
                            outlined
                            v-if="currentTasks === null"
                            type="error"
                        >
                            Could not find any current tasks, is celery / redis running?
                        </v-alert>
                        <v-btn
                            v-if="!progress && currentTasks !== null"
                            x-large
                            color="primary"
                            :loading="loading"
                            :disabled="loading"
                            @click="runScan"
                        >
                            Run Inventory Scan
                            <v-icon right>mdi-magnify-scan</v-icon>
                        </v-btn>
                        <v-col cols="8" v-if="progress">
                            <v-progress-linear v-model="progress" class="mb-5" rounded></v-progress-linear>
                            <p
                                v-text="`${this.taskInfo.current} of ${this.taskInfo.total} - ${this.taskInfo.current_ann}`"
                            ></p>
                            <p>{{ taskInfo.num_endpoints }} endpoints scanned</p>
                            <p>{{ taskInfo.num_issues }} issues found</p>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-container>
                    <v-row>
                        <v-col>
                            <h3 class="deep-orange--text">
                                <v-icon class="mb-1" color="deep-orange">mdi-alert-circle-outline</v-icon>
                                {{ numIssues }} total issues found
                            </h3>
                        </v-col>
                        <v-col class="d-flex justify-end">
                            <v-btn
                                :loading="loading"
                                :disabled="loading"
                                small
                                @click="runScanAgain"
                            >
                                Run Scan Again
                                <v-icon right>mdi-magnify-scan</v-icon>
                            </v-btn>
                            <v-btn
                                class="mx-2"
                                :loading="loading"
                                :disabled="loading"
                                small
                                @click="exportToCSV"
                            >
                                Export To CSV
                                <v-icon right>mdi-export</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-for="(fpa, i) in issues" :key="i">
                        <v-col>
                            <h3>{{ fpa.fpa_name }}</h3>
                            <div>
                                <p class="red--text font-weight-bold">{{ fpa.num_issues }} issues</p>
                                <ul>
                                    <li v-for="(ep, i2) in fpa.issues" :key="i2">
                                        <a
                                            :href="jiraLink(ep.endpoint, true)"
                                            target="_blank"
                                        >{{ ep.endpoint }}</a>
                                        <ul>
                                            <li
                                                v-for="(epIssue, i3) in ep.issues"
                                                :key="i3"
                                            >
                                                {{ epIssue.msg }}
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-row>
        </v-container>
    </v-main>
</template>
<script>
import axios from "axios";

export default {
    name: "ScanForIssues",
    data() {
        return {
            currentTasks: [],
            taskId: null,
            taskInfo: null,
            loading: false,
            polling: null,
            progress: null,
            numIssues: 0,
            issues: [],
        };
    },
    computed: {},
    methods: {
        async runScan() {
            this.loading = true;
            const response = await axios.get(`/inventory/scan/?runScan=1`);
            this.taskId = response.data.taskId;
            this.checkStatus();
        },
        runScanAgain() {
            this.issues = [];
            this.runScan();
        },
        stopPolling() {
            clearInterval(this.polling);
            this.loading = false;
        },
        checkStatus() {
            this.polling = setInterval(async () => {
                const response = await axios.get(
                    `/inventory/scan/?checkTask=${this.taskId}`
                );
                switch (response.data.task_status) {
                    case "PENDING":
                    case "PROGRESS": {
                        const taskInfo = (this.taskInfo =
                            response.data.task_info);
                        if (taskInfo)
                            this.progress =
                                (taskInfo.current / taskInfo.total) * 100;
                        break;
                    }
                    case "SUCCESS": {
                        this.progress = null;
                        this.numIssues = response.data.num_issues;
                        this.issues = response.data.issues;
                        this.stopPolling();
                        break;
                    }
                    case "FAILURE": {
                        this.$store.commit(
                            "setErrorSnack",
                            response.data.task_info
                        );
                        this.stopPolling();
                        break;
                    }
                    default:
                        this.stopPolling();
                }
            }, 1000);
        },
        async exportToCSV() {
            this.loading = true;

            const response = await axios.get(
                `/inventory/scan/export/${this.taskId}/`,
                {responseType: 'blob'}
            );

            if (!window.navigator.msSaveOrOpenBlob){
                const contentDisposition = response.headers['content-disposition'];
                
                const url = URL.createObjectURL(new Blob([response.data],{type:response.headers['content-type']}));
                const link = document.createElement('a');
                const fileName = contentDisposition.match(/filename=(?<filename>[^,;]+)/)[1];
                
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                // 
                // TODO: this could be handled with something like:
                // let blob = new Blob([data], { type: response.headers['content-type'] });
                // window.navigator.msSaveOrOpenBlob(blob, fileName);
                // but for now, too bad
                //
                alert('Fail - what version of IE are you using?');
            }
            this.loading = false;
        }
    },
    async mounted() {
        this.loading = true;
        const response = await axios.get(`/inventory/scan/`);
        this.currentTasks = response.data.currentTasks;

        if (this.currentTasks && this.currentTasks.length) {
            this.taskId = this.currentTasks[0].id;
            this.checkStatus();
        } else {
            this.loading = false;
        }
    },
    beforeDestroy() {
        clearInterval(this.polling);
    },
};
</script>